body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  height: 3rem;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  cursor: pointer;
  line-height: 3rem;
  text-align: center;
  color: white;
}

.dark {
  background-color: #151515;
}

.less-dark {
  background-color: #1f1f1f;
}

.button:hover {
  background-image: linear-gradient(
    140deg,
    rgb(252, 210, 72),
    rgb(180, 138, 0)
  );
}

.flex-even {
  flex-grow: 1;
  flex-basis: 0;
}

.gold {
  background: linear-gradient(140deg, rgb(252, 210, 72), rgb(180, 138, 0));
}

.gold-button {
  color: white;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
}

.bg-dark {
  background-color: #000000 !important;
}

.dropzone {
  display: flex;
  width: 100%;
  height: 10rem;
  border: 1px solid black;
  border-radius: 0.2rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dropzone p {
  margin: 0 1rem 0 1rem;
}

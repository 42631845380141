.divTitle {
  background-color: black;
  color: white;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  text-align: center;
  padding: 0.5rem;
}

hr {
  margin: 0.5rem 0 0.5rem 0 !important;
}

.col-form-label {
  font-weight: bold;
}
